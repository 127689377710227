/*-----------------------------------------------------------------------------------

    Template Name: Braintech - IT Solutions and Technology Startup React Template
    Description: IT Solutions and Technology Startup React Template
    Author: rs-theme
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

01. Theme default CSS
2. header
3. about
4. slider
5. footer
6. service
7. team
8. cta
9. project
10. testimonial
11. contact
12. carrer
13. blog
14.shop

-----------------------------------------------------------------------------------*/
@import 'variables';
@import 'mixins';
@import 'common';
@import 'overlay';
@import 'header';
@import 'btn';
@import 'about';
@import 'slider';
@import 'banner';
@import 'footer';
@import 'services';
@import 'team';
@import 'team-details';
@import 'cta';
@import 'chooseus';
@import 'project';
@import 'testimonial';
@import 'counter';
@import 'video';
@import 'contact';
@import 'appointment_form';
@import 'blog';
@import 'brand';
@import 'newsletters';
@import 'faq';
@import 'process';
@import 'widget';
@import 'progress';
@import 'skills';
@import 'feature';
@import 'callus';
@import 'pricing';
@import 'breadcrumb';
@import 'pagination';
@import 'shop';
@import 'technology';
@import 'scrollup';
@import 'error';
@import 'responsive';
@import 'animations';
@import 'spacing';
@import 'mobile-menu';
@import 'canvas-menu';
@import 'search-modal';
@import '../../assets/fonts/flaticon';
@import '../../assets/fonts/fontAwesome';


