.rs-team{
    background: url(../img/bg/team-bg.png);
    background-position: center center;
    background-size: cover;
    background-color: #000060;
    .team-item-wrap{
        .team-wrap{
            padding: 9px 27px 25px 27px;
            background: url(../img/team/shape.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            .image-inner{
                overflow: hidden;
                border-radius: 50%;
                a{
                    img{
                transform: scale(1);
                -webkit-transition: 0.3s ease-in-out;
                transition: 0.3s ease-in-out;
                    }
                }
            }
        }
        .team-content{
            padding: 20px 20px 20px;
            .person-name{
                font-size: 22px;
                font-weight: 600;
                line-height: 44px;
                margin-bottom: 5px; 
                a{
                    color: $whiteColor;
                }                
            }
            .designation{
                display: block;
                font-size: 15px;
                font-weight: 400;
                line-height: 26px;
                color: $whiteColor;
                margin-bottom: 15px;             
            }
            .team-social{
                li{
                    display: inline-block;
                    margin-right: 10px;
                    a{
                        i{
                            font-size: 15px;
                            line-height: 14px;
                            font-weight: 400;
                            color: $whiteColor;                                 
                        }
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
        &:hover{
            .team-wrap{
                .image-inner{
              
                    a{
                        img{
                            transform: scale(1.3);
                        }
                    }
                }
            }
        }
    }
    .owl-dots {
        text-align: center;
        margin-top: 30px;
        .owl-dot{
            width: 25px;
            height: 8px;
            display: inline-block;
            margin: 0 6px;
            padding: 3px 0;
            border-radius: 30px;
            border: none;
            transition: $transition;
            background: #0B70E1;
            box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
            &:hover,
            &.active {
              width: 40px;
              background: #0B70E1;
              opacity: 1;
            }
        }
    }
    .owl-stage-outer {
        padding: 10px;
        margin: -10px;
    }
    .owl-carousel {
        .owl-nav {
            > div {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 45px;
                height: 45px;
                display: inline-block;
                background: #f6f2ed;
                line-height: 47px;
                text-align: center;
                border-radius: 50%;
                transition: $transition;
                transition-delay: 0.70s;
                visibility: hidden;
                opacity: 0;
                i {
                    font-size: 18px;
                    color: $titleColor;
                }
            }
            .owl-next {
                right: 0;
            }
            .owl-prev {
                left: 0;
            }
        }
        &:hover {
            .owl-nav {
                > div {
                    transition-delay: 0s;
                    visibility: visible;
                    opacity: 1;
                }
                .owl-next {
                    right: -35px;
                }
                .owl-prev {
                    left: -35px;
                }
            }
        }
    }
    &.modify-style{
        background: url(../img/bg/team-bg.png);
        background-color: #F6F7F9;
        .team-item-wrap{
            .team-content{
                padding: 20px 20px 20px;
                .person-name{
                    a{
                        color: $titleColor;
                    }                
                }
                .designation{
                    color: $titleColor;           
                }
                .team-social{
                    li{
                        a{
                            i{
                                color: $bodyColor;                                
                            }
                        }
                    }
                }
            }
        }
        &.modify1{
            background-color: #000000;
            .team-item-wrap{
                .team-content{
                    padding: 20px 20px 20px;
                    .person-name{
                        a{
                            color: $whiteColor;
                        }                
                    }
                    .designation{
                        color: $whiteColor;           
                    }
                    .team-social{
                        li{
                            a{
                                i{
                                    color: $whiteColor;                                
                                }
                            }
                        }
                    }
                }
            }
            .owl-dots {
                text-align: center;
                .owl-dot{
                    width: 25px;
                    height: 8px;
                    display: inline-block;
                    margin: 0 6px;
                    padding: 3px 0;
                    border-radius: 30px;
                    border: none;
                    transition: $transition;
                    background: #0B70E1;
                    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
                    &:hover,
                    &.active {
                      width: 40px;
                      background: #FD6509;
                      opacity: 1;
                    }
                }
            }
        }
    }
    &.modify-style2{
        border-radius: 270px 0px 0px 0px;
    }
    &.modify1{
        background: unset;
        .team-item-wrap{
            .team-content{
                .person-name{
                    a{
                        color: $titleColor;
                    }                
                }
                .designation{
                    color: $titleColor;            
                }
                .team-social{
                    li{
                        a{
                            i{
                                color: $bodyColor;                                 
                            }
                        }
                    }
                }
            }
        }
    }
    &.style2{
        background: unset;
        .team-item{
            .images-part{
                position: relative;
                overflow: hidden;
                .social-icon{
                    position: absolute;
                    bottom: 0;
                    right: -50px;
                    width: 50px;
                    background: $secondaryColor;
                    padding: 12px 6px 12px;
                    transition: all 0.3s ease 0s;
                    visibility: hidden;
                    opacity: 0;
                    a{

                      i{
                            display: inline-block;
                            font-size: 15px;
                            line-height: 35px;
                            width: 35px;
                            height: 35px;
                            color: $whiteColor;
                            background: $secondaryColor;
                            text-align: center;
                            border-radius: 50%;
                            transition: all 0.3s ease 0s;
                            margin: 0;
                        }
                    }                  
                }              
            }
            .team-content{
                box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
                background-color: #FFFFFF;
                text-align: center;
                margin: 0;
                padding: 20px;
                .person-name{
                    font-weight: 600;
                    font-size: 20px;
                    margin-bottom: 2px;
                    text-transform: capitalize;
                    line-height: 1.3;
                    a{
                       color: #0A0A0A;
                       &:hover{
                            color: $primaryColor;
                       }
                    }
                }    
                .designation{                        
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: uppercase;
                    color: #555555;
                    display: block;
                }
            }
            &:hover {
                .images-part {
                    .social-icon {
                        right: 0;
                        width: 50px;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
        &.modify-style {
            background: #f5f6fe;
            .owl-stage-outer {
                padding: unset;
                margin: unset;
            }
            .team-item{
                .images-part{
                    img{
                        border-radius: 5px 5px 0 0;
                    }
                    .social-icon {
                        background: $purpleColor;
                    }
                }
                .team-content{
                    .person-name{
                        font-size: 20px;
                        font-weight: 700;
                        margin-bottom: 8px;
                        a{
                            &:hover{
                                color: #787cf2;
                            }
                        }
                    }
                    .designation{
                        font-size: 15px;
                        text-transform: capitalize;
                    }  
                }
            }
            .owl-dots {
                text-align: center;
                .owl-dot{
                    width: 25px;
                    height: 8px;
                    display: inline-block;
                    margin: 0 6px;
                    padding: 3px 0;
                    border-radius: 30px;
                    border: none;
                    transition: $transition;
                    background: #787cf2;
                    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
                    &:hover,
                    &.active {
                        width: 40px;
                        background: #787cf2;
                        opacity: 1;
                    }
                }
            }
        }
        .owl-dots {
            margin: 40px auto 0;
        }
    }
}
.rs-team-Single{
    .btm-info-team{
        position: relative;
        box-shadow: 0px 0px 32px 0px rgba(7, 28, 31, 0.05);
        background: #fff;
        margin-bottom: 70px;
        padding: 40px;
        .images-part{
            padding: 0 30px 0 0;
            img{
                border-radius: 50%;
            }
        }
        .con-info{
            position: relative;
            .designation-info{
                font-size: 16px;
                line-height: 27px;
                font-weight: 400;
                color: #0B70E1;
                display: block;
                text-transform: uppercase;
            }
            .title{
                font-size: 36px;
                line-height: 46px;
                font-weight: 700;
                color: $titleColor;
                margin-bottom: 10px;
            }
            .short-desc{
                font-size: 16px;
                line-height: 26px;
                font-weight: 400;
                color: $bodyColor;
                margin-bottom: 25px;
                padding-right: 50px;
            }
            .ps-informations{
                .personal-info{
                    li{
                        margin: 5px 0 10px;
                        padding: 0;
                        display: block;
                        span{
                            i{
                                &:before{
                                    color: #0b70e1;
                                    margin-right: 18px;
                                    position: relative;
                                    top: 2px;
                                    font-weight: 500;
                                    display: inline-block;
                                    text-align: center;
                                }
                            }
                        }
                        a{
                            color: #555;
                            &:hover{
                                color: $primaryColor;
                            }
                        }
                    }
                }
                .social-info{
                    margin-top: 15px;
                    li{
                        display: inline-block;
                        margin-right: 18px;
                        a{
                            color: #555;
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }
            &:before{
                content: "\f10b";
                font-size: 250px;
                position: absolute;
                right: 130px;
                color: #0a0a0a;
                font-family: "Flaticon";
                height: 100%;
                top: 250px;
                opacity: 0.02;
            }
        }
    }
}